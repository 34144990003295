import * as React from 'react'
import Nav from "../components/nav";
import Footer from "../components/footer";
import BackgroundImage from "../components/background-image";
import BGImage from "../images/contact-page-image.jpg"
import BGImageM from "../images/forest-m.jpg"
import ContactForm from "../components/contact-form";
import {Helmet} from "react-helmet";

const Contact = () => {
    return(
        <main>
            <Helmet>
                <title>Rancho Bernardo Maids - Contact</title>
                <meta name="description" content="Contact Rancho Bernardo Maids for the best cleaning services, deep cleans, & move outs for houses, apartments, condos & more" />
                <meta name="keywords" content="Deep Cleaning, Standard House Cleaning, Move Outs, Party Clean Ups"/>
                <meta name="robots" content="index, follow" />
            </Helmet>
            <Nav/>
            <BackgroundImage image={BGImage} mobileImage={BGImage} alt={"forest background"} height={"h-128"}  hero={true}>
                <h1 className={"text-8xl text-center text-white p-6"}>Contact Us</h1>
            </BackgroundImage>
            <ContactForm/>
            <Footer/>
        </main>
    )
}

export default Contact